import * as Permissions from 'common/constants/Permissions';

interface Menu {
  title: string;
  icon: string;
  subMenus: Array<{
    permission: string[];
    title: string;
    path: string;
  }>;
}

const layout: Menu[] = [
  {
    title: 'Home',
    icon: 'home',
    subMenus: [
      {
        permission: [],
        title: 'Home',
        path: '/',
      },
    ],
  },
  {
    title: 'Booking & Routing',
    icon: 'store',
    subMenus: [
      {
        permission: Permissions.BOOKING_CENTER_PAGE,
        title: 'Booking Center',
        path: '/booking',
      },
      {
        permission: Permissions.ROUTING_CENTER_PAGE,
        title: 'Routing Center',
        path: '/operations/routing',
      },
    ],
  },
  {
    title: 'Operations',
    icon: 'settings',
    subMenus: [
      {
        permission: Permissions.ALTERNATE_GEO_LOCATION,
        title: 'Alternate Geo Location',
        path: '/operations/alternate-geo-location',
      },
      {
        permission: Permissions.CONTINGENCY_BANNER,
        title: 'Contingency Banner',
        path: '/operations/contingency-banner',
      },
      {
        permission: Permissions.QUICK_FILTER,
        title: 'Quick Filter',
        path: '/operations/quick-filter',
      },
      {
        permission: Permissions.RULE_DISPLAY_PLATFORM,
        title: 'Rule Display Platform',
        path: '/operations/rule-display-platform',
      },
      {
        permission: Permissions.LANDING_PAGE_MENU_CONFIG,
        title: 'Landing Page Menu Config',
        path: '/operations/landing-page-menu-config',
      },
      {
        permission: Permissions.PRIDE_TOOLS,
        title: 'Pride Tools',
        path: '/operations/pride-tools',
      },
      {
        permission: Permissions.ACCESS_CODE_TOOL,
        title: 'Access Code Tool',
        path: '/operations/access-code',
      },
      {
        permission: Permissions.PRODUCT_PURCHASE_CONFIG,
        title: 'Product Purchase Config',
        path: '/operations/product-purchase-config',
      },
      {
        permission: Permissions.VIRTUAL_WAITING_ROOM_CONFIG,
        title: 'Virtual Waiting Room Config',
        path: '/operations/virtual-waiting-room-config',
      },
    ],
  },
  {
    title: 'Activation & Deactivation',
    icon: 'check-circle',
    subMenus: [
      {
        permission: Permissions.ACTIVE_EXP_GEO,
        title: 'Active Geo & Experience',
        path: '/active-items/exp-geo',
      },
      {
        permission: Permissions.BMG_WHITELIST,
        title: 'BMG Whitelist',
        path: '/operations/white-list-bmg',
      },
      {
        permission: Permissions.POT_WHITELIST,
        title: 'POT Whitelist',
        path: '/operations/white-list-pot',
      },
      {
        permission: Permissions.EATS_BLACKLIST,
        title: 'Eats Blacklist',
        path: '/operations/black-list-eats',
      },
      {
        permission: [Permissions.UPDATE_EXP_PRODUCT],
        title: 'Product Activation',
        path: '/operations/activate-product',
      },
      {
        permission: Permissions.HIDDEN_PRODUCT,
        title: 'Hidden Product',
        path: '/operations/hidden-product',
      },
    ],
  },
  {
    title: 'Moderation',
    icon: 'rate-review',
    subMenus: [
      {
        permission: Permissions.REVIEW_MODERATION_PAGE,
        title: 'Review Moderation',
        path: '/moderation/review',
      },
      {
        permission: [Permissions.READ_STATIC_DATA_PRODUCT_LIST],
        title: 'Content Management',
        path: '/moderation/content',
      },
    ],
  },
  {
    title: 'Tagging & Mapping',
    icon: 'beenhere',
    subMenus: [
      {
        permission: Permissions.LANDMARK_MAPPING_PAGE,
        title: 'Landmark Mapping',
        path: '/mappings/landmark',
      },
      {
        permission: Permissions.PRODUCT_MAPPING_PAGE,
        title: 'Product Mapping',
        path: '/mappings/product',
      },
      {
        permission: Permissions.TAG_MAPPING,
        title: 'Tag Mapping',
        path: '/mappings/tag-mapping',
      },
      {
        permission: Permissions.SEARCHABLE_ITEM,
        title: 'Searchable Item',
        path: '/searches/searchable-item',
      },
    ],
  },
  {
    title: 'Miscellaneous',
    icon: 'extension',
    subMenus: [
      {
        permission: [Permissions.FLUSH_CACHE],
        title: 'Flush Cache',
        path: '/miscellaneous/flush-cache',
      },
      {
        permission: [Permissions.SINGLE_JOB_IMPORT],
        title: 'Single Job Import',
        path: '/miscellaneous/single-job-import',
      },
      {
        permission: [],
        title: 'Excel File Import',
        path: '/miscellaneous/excel-file-import',
      },
      {
        permission: [Permissions.GENERATE_STATIC_PAGE_SITEMAP],
        title: 'Generate Sitemap',
        path: '/miscellaneous/generate-sitemap',
      },
      {
        permission: [Permissions.LIST_STATIC_PAGE_KEYWORD],
        title: 'Download Static Page',
        path: '/miscellaneous/download-static-page',
      },
      {
        permission: [Permissions.READ_GOOGLE_TTD_PRODUCT_FEED],
        title: 'Google - Things To Do',
        path: '/miscellaneous/product-feed',
      },
      {
        permission: [Permissions.READ_TICKET_FILTER],
        title: 'Ticket Filter',
        path: '/miscellaneous/ticket-filter',
      },
    ],
  },
];

export default layout;
