import React, { createContext, useContext, useMemo } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import { connect } from 'react-redux';
import { css } from 'react-emotion';
import { OIDCAuthorize, OIDCLayout, OIDCUserSelector, getPermissions } from '@traveloka/soya-auth';
import { Block, Icon } from '@traveloka/soya-components';
import LayoutConfig from './layout-menu';
import { hasPermissions } from '../../utils/PermissionUtil';
import AuthContext from '../AuthContext';

type ReduxState = {
  token?: {
    tokenBody?: {
      'https://traveloka/permission'?: string[];
      permissions?: string[];
    };
  };
};

type OwnProps = {
  children: React.ReactNode;
  title?: string;
  requiredPermissions?: string[];
};

type StateProps = {
  permissions: string[];
  accessToken: string;
  userEmail: string;
};

type Props = StateProps & OwnProps;

interface SubMenu {
  title: string;
  path: string;
}

export interface MenuData {
  title: React.ReactNode;
  iconText: React.ReactNode;
  subMenuList: SubMenu[];
}

const MenuListContext = createContext<MenuData[]>([]);
export const useMenuList = () => useContext(MenuListContext);

const Layout = (props: Props) => {
  const authContext = {
    permissions: props.permissions,
    accessToken: props.accessToken,
    userEmail: props.userEmail,
  };

  const menuList = useMemo(() => {
    return LayoutConfig.reduce<MenuData[]>((menus, currentMenu) => {
      const filteredSubMenu = currentMenu.subMenus.filter(menu => hasPermissions(props.permissions, menu.permission));
      if (filteredSubMenu && filteredSubMenu.length > 0) {
        return [
          ...menus,
          {
            title: currentMenu.title,
            iconText: <Icon icon={currentMenu.icon} />,
            subMenuList: filteredSubMenu,
          },
        ];
      }
      return menus;
    }, []);
  }, [props.permissions]);

  const handleUnauthorizedCallback = () => (window.location.href = '/');

  const sideMenuData = menuList.map(({ subMenuList, ...rest }) => ({
    ...rest,
    links: subMenuList.map(subMenu => (
      <Link key={subMenu.path} href={subMenu.path} prefetch>
        <a href={subMenu.path}>{subMenu.title}</a>
      </Link>
    )),
  }));

  return (
    <OIDCAuthorize requiredPermissions={props.requiredPermissions} unauthorizedCallback={handleUnauthorizedCallback}>
      <OIDCLayout menuData={sideMenuData}>
        <Head>
          <meta name="referrer" content="no-referrer" />
          <title>{props.title || 'Experience Ops Tools'}</title>
        </Head>
        <Block
          className={css`
            min-width: 1080px;
          `}
        >
          <MenuListContext.Provider value={menuList}>
            <AuthContext.Provider value={authContext}>{props.children}</AuthContext.Provider>
          </MenuListContext.Provider>
        </Block>
      </OIDCLayout>
    </OIDCAuthorize>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const userSelector = OIDCUserSelector(state);
  const token = userSelector.getToken();

  const permissions = (token && token.tokenBody && getPermissions(token.tokenBody)) || [];

  return {
    permissions,
    accessToken: (token && token.accessToken) || '',
    userEmail: (token && token.userEmail) || '',
  };
};

export default connect(mapStateToProps)(Layout);
