import { useContext } from 'react';
import AuthContext from '../components/AuthContext';

/**
 * Check whether user has all the required permission
 * @param {string[]} userPermission user's permission from auth
 * @param {string[]} requiredPermission permission to check
 * @returns {boolean}
 */
export const hasPermissions = (userPermissions: string[] | null, requiredPermissions: string[] | string) => {
  if (!userPermissions) {
    return false;
  }

  const reqPermissions = Array.isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions];

  return reqPermissions.every(permission => userPermissions.includes(permission));
};

/**
 * Check whether user has some of the required permission
 * @param {string[]} userPermission user's permission from auth
 * @param {string[] | string} requiredPermission permission to check
 * @returns {boolean}
 */
export const hasSomePermissions = (userPermissions: string[] | null, requiredPermissions: string[] | string) => {
  if (!userPermissions) {
    return false;
  }

  const reqPermissions = Array.isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions];

  return reqPermissions.some(permission => userPermissions.includes(permission));
};

export interface UsePermission {
  hasPermissions: (requiredPermissions: string[]) => boolean;
  hasSomePermissions: (requiredPermissions: string[]) => boolean;
}

export const usePermissions = () => {
  const auth = useContext(AuthContext);
  const permissions = (auth && auth.permissions) || [];

  return {
    /**
     * Check whether user has all the required permission from auth
     * @param {string[] | string} requiredPermission permission to check
     * @returns {boolean}
     */
    hasPermissions: (requiredPermissions: string[] | string) => hasPermissions(permissions, requiredPermissions),

    /**
     * Check whether user has some of the required permission from auth
     * @param {string[] | string} requiredPermission permission to check
     * @returns {boolean}
     */
    hasSomePermissions: (requiredPermissions: string[] | string) =>
      hasSomePermissions(permissions, requiredPermissions),
  };
};
