/**
 * This utility creates common authorization context that hold the authorization informations
 * such as: permissions, accessToken & userEmail.
 */

import { createContext } from 'react';

export type Auth = {
  permissions: string[];
  accessToken: string;
  userEmail: string;
};

export default createContext<Auth>(null!);
